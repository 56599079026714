import React from 'react'
import intl from 'react-intl-universal';
import { Container } from 'reactstrap';

const About = (props) => (
  <div className="page page-regular page-about">
    <Container>
      <h2>{intl.get('pageAbout')}</h2>
      {intl.getHTML('aboutText')}
    </Container>
  </div>
)

export default About;
